import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


import io from "socket.io-client"


const socket = io("ws://localhost:8765", {
    cors: {
        origin: "http://"+window.location.hostname+":3000",
        methods: ["GET", "POST"]
    }
});

socket.emit("get_config",{
}, (data) => {
    console.log(data)
    ReactDOM.render(<App socket={socket} config={data} />, document.getElementById('root'));
    serviceWorker.unregister();
});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

