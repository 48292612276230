import React, {Component} from 'react';



import { withStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';



import GameView from './views/game/GameView'


const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});




class App extends React.Component{
  componentDidMount() {
    this.socket.on("connect", () => {
      console.log(this.socket.id); // "G5p5..."

    });

    this.socket.on("message", (...args) => {
      console.log("msg"+args)
    });



    /*
    client.onclose = () => {
      console.log('WebSocket Client disconnected');
    };
        */




  }

  constructor(props) {
    super(props);
    this.socket = this.props.socket

  }

  render()
  {
    const { classes } = this.props;
    return (

        <Container component="main" maxWidth="xs">

              <GameView socket={this.socket} game_config={this.props.config[1]} id={1}/>

        </Container>

    );
  }
}
App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(App);
