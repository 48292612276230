import React from 'react'

import PuzzleView  from "./puzzle/PuzzleView";

class GameView extends React.Component  {
    render()
    {
        const puzzles = this.props.game_config.puzzles
        let renderedPuzzles = []

        for (var key in puzzles) {
            let puzzle = puzzles[key]
            renderedPuzzles.push(
                <PuzzleView className="post-excerpt" key={puzzle.id} id={puzzle.id} game_id={this.props.id} name={puzzle.name} puztype={puzzle.type} inputs={puzzle.inputs} socket={this.props.socket} store={this.props.store}/>
            )

        }


        return (
            <section className="posts-list">
                <h2>Puzzles</h2>
                {renderedPuzzles}
            </section>
        )
    }
}
// pick the parts of the Redux state that you want
// to use as props in your connected component

export default GameView

