import React from "react";
import PuzzleIOView, {puzzleIOReducer} from "./io/PuzzleIOView";




class PuzzleView extends React.Component {
    // This syntax ensures `this` is bound within handleClick.
    // Warning: this is *experimental* syntax.

    render() {
        const inputs = this.props.inputs
        let renderedInputs = []

        for (var key in inputs) {
            let input = inputs[key]
            renderedInputs.push(
                <PuzzleIOView className="post-excerpt" key={key} puzid = {this.props.id} game_id={this.props.game_id} id={key} socket={this.props.socket} config={input}/>
            )
        }

        return (
            <section className="posts-list">
                <h2>{this.props.name}</h2>
                {renderedInputs}
            </section>



        );
    }
}

export default PuzzleView;



