import React from "react";
import Button from "@material-ui/core/Button";

import io from "socket.io-client";

class PuzzleIOView extends React.Component {
    // This syntax ensures `this` is bound within handleClick.
    // Warning: this is *experimental* syntax.
    handleClick = () => {
        this.setState({is_active:false})
        let action = "RELEASED"
        if(this.state.value === "RELEASED"){
            action="PRESSED"
        }
        console.log(action)
        this.socket.emit("value",{
            action:"set_value",
            state: action
        }, (data) => {
            this.setState({is_active:true})
        });
    }

    render() {
        return (
            <Button
                onClick={this.handleClick}
                type="submit"
                fullWidth
                variant="contained"
                color={this.state.state === 'PRESSED' ? "primary" : 'default'}
                disabled={!this.state.is_active}

            >
                {this.props.id+": "+this.state.state}
            </Button>

        );
    }

    constructor(props) {
        super(props);
        this.state={value:this.props.config.value, is_active:true, state:this.props.config.state}
        this.socket = io(this.props.socket.io.uri+"/PUZ/"+this.props.puzid+"/IO/"+this.props.id)

        /*
        this.socket.emit("state",{
            action:"get_state",
        }, (data) => {
            console.log(data)
            this.setState({value:data})
        });

         */

        this.socket.on("state", (...args) => {
            console.log(args)
            this.setState({state:args[0]})

        });
        this.socket.on("value", (...args) => {
            console.log(args)
            this.setState({value:args[0]})

        });
        this.socket.on("test", (...args) => {
            console.log("test call recv")
            setTimeout(function() {
                args[1]("fuckers");
            }, (3 * 1000));

        });
    }
}


export default PuzzleIOView;

